import NewTable from 'components/NewTable/NewTable';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { REST } from 'utils/axiosHelper';
import loggedIn from 'utils/loggedIn'
import { Redirect } from 'react-router-dom'
// STYLE
import './Drafts.scss';

const Drafts = () => {
	const [drafts, setDrafts] = useState(null);
	const [elements, setElements] = useState(null);
	const [size, setSize] = useState(5);
	const [page, setPage] = useState(0);

	const getData = async () => {
		await REST.get(`/api/frr/project/getAllDraftProjects?page=${page}&size=${size}`)
			.then((answer) => {
				setDrafts(answer.data.content);
				setElements(answer.data.totalElements)
			})
			.catch((err) => {
				console.log(`Err: `, err);
			});
	};

	useEffect(() => {
		getData();
		// eslint-disable-next-line
	}, []);
	useEffect(() => {
		getData();
		// eslint-disable-next-line
	}, [page, size]);

	const handlePreview = (id) => {
		window.location.href = `/drafts/${id}`;
	};

	return !loggedIn() ? (
		<Redirect to="/login" />
	) : (
		<div className="draftContainer">
			<div className="draftTitleContainer">
				<h1 className="draftTitle">Drafts</h1>
			</div>
			<div style={{ maxHeight: '70%' }}>
				<NewTable
					tableData={drafts}
					showViewButton={true}
					preview={(e) => handlePreview(e)}
					elements={elements}
					setSize={setSize}
					page={page}
					setPage={setPage}
					isDraft={true}
				/>
			</div>
		</div>
	);
};

// IT'S A MUST
Drafts.propTypes = {
	className: PropTypes.string // if it's mandatory prop add ".isRequired"
};

export default Drafts;
