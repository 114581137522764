import NewTable from 'components/NewTable/NewTable';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { REST } from 'utils/axiosHelper';
// STYLE
import './Approved.scss';

const Approved = () => {
	const [projects, setProjects] = useState(null);
	const [elements, setElements] = useState(null);
	const [size, setSize] = useState(5);
	const [page, setPage] = useState(0);

	const getData = async (pageToLoad) => {
		await REST.get(`/api/frr/project/getAllActiveProjects?page=${page}&size=${size}`)
			.then((answer) => {
				setProjects(answer.data.content);
				setElements(answer.data.totalElements)
			})
			.catch((err) => {
				console.log(`Err: `, err);
			});
	};

	useEffect(() => {
		getData();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		getData();
		// eslint-disable-next-line
	}, [page, size]);

	const handlePreview = (id) => {
		window.location.href = `/approved/${id}`;
	};

	return (
		<div className="approvedContainer">
			<div className="approvedTitleContainer">
				<h1 className="approvedTitle">Approved IPbS</h1>
			</div>
			<div>
				<NewTable
					tableData={projects}
					showViewButton={true}
					preview={(e) => handlePreview(e)}
					elements={elements}
					setSize={setSize}
					page={page}
					setPage={setPage}
					isDraft={false}
				/>
			</div>
		</div>
	);
};

// IT'S A MUST
Approved.propTypes = {
	className: PropTypes.string // if it's mandatory prop add ".isRequired"
};

export default Approved;
