import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import './PreviewDrafts.scss';
import { store } from 'store/Store'
import { REST, beURL } from 'utils/axiosHelper';
import CustomButton from 'components/CustomButton/CustomButton';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';

const PreviewDrafts = () => {
    const [data, setData] = useState(null);
    const [documents, setDocuments] = useState(null);
    const [nextStep, setNextStep] = useState(false);
    const [loading, setLoading] = useState(false);
    const timer = React.useRef();

    useEffect(() => {
        setData(store.getState().draftReducer.data)
    }, [])

    const getDocuemnts = async () => {
        await REST.get(`/api/frr/project/getAllProjectDocuments?projectId=${data?.id}`)
            .then((answer) => {
                setDocuments(answer.data);
            })
            .catch((err) => {
                console.log(`Err: `, err);
            });
    };

    useEffect(() => {
        getDocuemnts();
        // eslint-disable-next-line
    }, [data])



    const handleProjectStatus = async (status) => {
        await REST.put(`/api/frr/project/changeProjectStatus?projectId=${data.id}&status=${status}`)
            .then((answer) => {
                window.location.href = "/";
                setNextStep(true)
            })
            .catch((err) => {
                console.log(`Err: `, err);
            });
    }

    const handleMRSB = async (status) => {
        await REST.put(`/api/frr/project/changeProjectStatus?projectId=${data.id}&status=${status}`)
            .then((answer) => {
                window.location.href = "/";
                setNextStep(true)
            })
            .catch((err) => {
                console.log(`Err: `, err);
            });
    }


    useEffect(() => {
        return () => {
            clearTimeout(timer.current);
        };
    }, []);

    const handleButtonClick = () => {
        if (!loading) {
            setLoading(true);
            setTimeout(() => {
                setNextStep(true)
                setLoading(false);
            }, 2000);
        }
    };

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
        <>
            {data &&
                <Grid container className="preview-draft-container">
                    <Grid item xs={12} className="title-container">
                        <img src={data?.artist?.imageUrl} alt="" className="artist-img" />
                        <div>
                            <h1 className="title"><b>{data?.artist?.name}:</b> {data?.projectName}</h1>
                            <p className='title'>{data?.artist?.about}</p>
                            <p className="title"><b>Followers:</b> {data?.artist?.followerCount}</p>
                        </div>
                    </Grid>

                    <Grid item container xs={12} className="data-body">
                        <Grid item xs={12} md={6} className="content-container">
                            <p className='colored'><b>Project :</b> {data?.title}</p>
                            <p><b>Project id :</b> {data.projectName}</p>
                            <p className='colored'><b>Project type:</b> ONE - FTIS</p>
                            <p><b>Project category:</b> {data.category}</p>
                            <p className='colored'><b>Genre:</b> {data.genre}</p>
                            <p><b>Tenor:</b> {data.tenor} {data.tenorSufix}</p>
                            <p className='colored'><b>Installments:</b> {data.installments}</p>
                        </Grid>
                        <Grid item xs={12} md={6} className="content-container">
                            <p><b>Estimated value:</b> {numberWithCommas(data.estimatedValue)}$</p>
                            <p className='colored'><b>IRO value offer:</b> {numberWithCommas(data.valueOffer)}$</p>
                            <p><b>Percentage:</b> {data.percentage}%</p>
                            <p className='colored'><b>Number of units for sale:</b> {numberWithCommas(data.numberOfUnitsSold)} units</p>
                            <p><b>Estimated YIELD:</b> {data.estimtedYield}%</p>
                            <p className='colored'><b>Estimated YIELD description:</b> {data.estimatedYieldDescription}</p>
                            <p><b>Price per unit:</b> {numberWithCommas(data.pricePerUnit)}$</p>
                        </Grid>

                    </Grid>
                    <Grid item xs={12} md={12} className="documents-container">
                        <div className="files-container">
                            <h2 className="title">Documents: </h2>
                            {documents?.map((item) => {
                                return (
                                    <Tooltip title={item.filename}>
                                        <div className="item">
                                            <a href={`${beURL}/api/frr/project/getDocumentByFilename?filename=${item.filename}`} target={'_blank'} rel="noreferrer">
                                                <InsertDriveFileOutlinedIcon style={{ width: 35, height: 35 }} />
                                            </a>
                                        </div>
                                    </Tooltip>
                                )
                            })}
                        </div>
                    </Grid>
                    {!nextStep && !loading &&
                        <Grid item container xs={12} className="btns-container">
                            <Grid item xs={12} md={6} className="btn">
                            </Grid>
                            <Grid item xs={12} md={6} className="second-half">
                                <div className="btn"
                                    // onClick={() => handleProjectStatus(true)}
                                    onClick={handleButtonClick}
                                >
                                    <CustomButton
                                        name={'Approve'}
                                        type="rounded"
                                        text="Send to mrsb"
                                        isEnabled={true}
                                    />
                                </div>
                                <div className="btn" onClick={() => handleProjectStatus(false)}>
                                    <CustomButton
                                        name={'Reject'}
                                        type="rounded"
                                        text="Reject"
                                        isEnabled={true}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    }
                    {loading && (
                        <CircularProgress
                            size={24}
                            sx={{
                                color: '#ff3366',
                                position: 'absolute',
                                top: '64%',
                                left: '85%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                            }}
                        />
                    )}
                    {nextStep &&
                        <Grid item container xs={12} className="btns-container">

                            <Grid item xs={12} md={6} className="btn">
                            </Grid>
                            <Grid item xs={12} md={6} className="second-half">
                                <div className="btn-title">
                                    Approved
                                </div>
                                <div className="btn"
                                    onClick={() => handleMRSB(true)}
                                >
                                    <CustomButton
                                        name={'Reject'}
                                        type="rounded"
                                        text="Ok"
                                        isEnabled={true}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            }
        </>
    )
}

PreviewDrafts.propTypes = {
    timer: PropTypes.number // if it's mandatory prop add ".isRequired"
};

export default PreviewDrafts;