import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import './DistributionTable.scss';
import { mock } from './mock/mockTable';

export default function NewTable(props) {
	const { data, setPage, page, setSize, issuerData, uploaded } = props;

	const [rowsPerPage, setRowsPerPage] = React.useState(5);
	const [value, setValue] = React.useState(new Date());
	const [mockDate, setMockData] = useState(mock);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setSize(parseInt(event.target.value, 10));
	};

	const shuffleArray = () => {
		const shuffledArray = mock?.sort((a, b) => 0.5 - Math.random());
		setMockData(shuffledArray)
	}


	useEffect(() => {
		shuffleArray()
	}, [value])

	function numberWithCommas(x) {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

	return (
		<div className="distribution-container">
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<DatePicker
					label="Select date"
					value={value}
					onChange={(newValue) => {
						setValue(newValue);
					}}
					renderInput={(params) => <TextField {...params} />}
				/>
			</LocalizationProvider>
			<TableContainer component={Paper} style={{
				maxHeight: 500
			}}>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell><p className="header-content" >Investor Name</p></TableCell>
							<TableCell align="center"><p className="header-content" >eMail</p></TableCell>
							<TableCell align="right"><p className="header-content" >No IPbS</p></TableCell>

						</TableRow>

						<TableRow>
							<TableCell><p className="header-content" >{issuerData?.artist?.name}</p></TableCell>
							<TableCell align="center"><p className="header-content" >{issuerData?.artist?.email}</p></TableCell>
							<TableCell align="right"><p className="header-content" >{numberWithCommas(((issuerData?.numberOfUnitsSold * 100) / issuerData?.percentage) - issuerData?.numberOfUnitsSold)}</p></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{uploaded && mockDate?.map((row) => (
							<TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
								<TableCell component="th" scope="row"><p className="table-content">{row?.username}</p></TableCell>
								<TableCell component="th" scope="row" align="center"><p className="table-content">{row?.email}</p></TableCell>
								<TableCell align="right"><p className="table-content">{numberWithCommas(row?.IPbS)}</p></TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			{/* <TablePagination
					rowsPerPageOptions={[5, 10, 25]}
					component="div"
					count={data?.length}
					rowsPerPage={rowsPerPage}
					page={page || 0}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/> */}
		</div>
	);
}
