import React from 'react';
import Grid from '@mui/material/Grid';
import './CreateNewIpro.scss';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
// import doc1 from './assets/pdf-test.pdf';
import Uploader from 'components/Uploader/Uploader';
import testpdf from 'assets/pdf-test.pdf';

const CreateNewIpro = () => {
    return (
        <Grid container className="new-ipro-container">
            <Grid item xs={12} className="title-container">
                <h1>
                    CREATE NEW IPRO
                </h1>
            </Grid>
            <Grid item xs={12} md={12} className="upload-docs">
                <h2 style={{ marginBottom: 20 }}>Upload your signed documents:</h2>
                <Uploader />
            </Grid>
            <Grid item xs={12} md={12} className="download-docs">
                <h2 style={{ marginBottom: 20 }}>Documents to sign:</h2>
                <div className="doc-item">
                    <h3 style={{ marginBottom: 10 }}>Securitization Agreement:</h3>
                    <a href={testpdf} target={'_blank'} rel="noreferrer">
                        <InsertDriveFileOutlinedIcon style={{ width: 35, height: 35 }} />
                    </a>
                </div>
                <div className="doc-item">
                    <h3 style={{ marginBottom: 10 }}>List of documents (appendixes to Securitization Agreement):</h3>
                    <a href={testpdf} target={'_blank'} rel="noreferrer">
                        <InsertDriveFileOutlinedIcon style={{ width: 35, height: 35 }} />
                    </a>
                </div>


            </Grid>

        </Grid>
    )
}

export default CreateNewIpro;