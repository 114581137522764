import React, { useState, useEffect } from 'react'
import './RegisterIpo.scss';
import { REST } from 'utils/axiosHelper';
import Grid from '@mui/material/Grid';
import CustomTable from 'components/CustomTable/CustomTable';

const RegisterIpo = () => {
    const [data, setData] = useState(null);
    const [size, setSize] = useState(5);
    const [page, setPage] = useState(0);
    const [elements, setElements] = useState(null);

    const tableHead = ["Issuer", "Project Type", "IPbS Code", "No. IPbS"];

    const getData = async () => {
        await REST.get(`/api/frr/getRegistredIpbos?page=${page}&size=${size}`)
            .then((answer) => {
                setData(answer.data.content);
                setElements(answer.data.totalElements)
            })
            .catch((err) => {
                console.log(`Err: `, err);
            });
    };

    useEffect(() => {
        getData();
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
		getData();
		// eslint-disable-next-line
	}, [page, size]);

    return (
        <Grid container className="register-ipo-container">
            <Grid item xs={12} className="title-container">
                <h1>Registered IPO</h1>
            </Grid>

            <Grid item xs={12} className="body-container">
                {data &&
                    <CustomTable
                        tableHead={tableHead}
                        tableData={data}
                        setSize={setSize}
                        setPage={setPage}
                        page={page}
                        elements={elements}
                    />
                }
            </Grid>
        </Grid>
    )
}

export default RegisterIpo;