import React from 'react';
import './LeftSidebar.scss';
import Sidebar from 'components/Sidebar/Sidebar';

const LeftSidebar = (props) => {
	const { children, browserHistory } = props;

    
	return (
		<div className="container">
			<div className="sidebar">
				<Sidebar browserHistory={browserHistory} />
			</div>
			<div className="children">{children}</div>
		</div>
	);
};

export default LeftSidebar;
