import axios from 'axios';
import { createBrowserHistory } from 'history';
import React, { Suspense, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from 'utils/Routes';


const App = () => {
	const token = localStorage.getItem('token');
	const browserHistory = createBrowserHistory({forceRefresh: true});

	useEffect(
		() => {
			// Logout user if token invalid
			axios.interceptors.response.use(
				(response) => {
					return response;
				},
				async function (error) {
					if (
						'response' in error &&
						typeof error.response !== 'undefined' &&
						error.response.status === 401 &&
						(token === undefined || token === null)
					) {
						// logout user if token is invalid / null / unauthorized
					}
					return Promise.reject(error);
				}
			);
		},
		[token]
	);

	return (
		<Suspense fallback="Loading...">
			<Router history={browserHistory}>
				<Routes browserHistory={browserHistory} />
			</Router>
		</Suspense>
	);
};

export default App;
