import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import LeftSidebar from 'components/LeftSidebar/LeftSidebar';
import Drafts from 'pages/Drafts/Drafts';
import LogIn from 'pages/LogIn/LogIn';
import NotFound from 'pages/NotFound/NotFound';
import PreviewApproved from 'pages/PreviewApproved/PreviewApproved';
import PreviewDrafts from 'pages/PreviewDrafts/PreviewDrafts';
import CreateNewIpro from 'pages/CreateNewIpro/CreateNewIpro';
import RegisterIpo from 'pages/RegisterIpo/RegisterIpo';
import Approved from 'pages/Approved/Approved';

const Routes = props => {
    const history = useHistory()

    return (
        <Switch>
            <Route exact path="/login">
                <LogIn history={history} />
            </Route>
            <LeftSidebar browserHistory={history}>
                <Route exact path="/">
                    <Drafts history={history} />
                </Route>
                <Route exact path="/approved">
                    <Approved history={history} />
                </Route>
                <Route exact path="/drafts/:id">
                    <PreviewDrafts history={history} />
                </Route>
                <Route exact path="/approved/:id">
                    <PreviewApproved history={history} />
                </Route>
                <Route exact path="/new-ipro">
                    <CreateNewIpro history={history} />
                </Route>
                <Route exact path="/register-ipo">
                    <RegisterIpo history={history} />
                </Route>
            </LeftSidebar>
            <Route path="/404">
                <NotFound />
            </Route>
        </Switch>
    )
}
export default Routes