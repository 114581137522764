import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import React from 'react';

export default function CustomTable(props) {
	const { tableData, tableHead, elements, setPage, page, setSize } = props;

	const [rowsPerPage, setRowsPerPage] = React.useState(5);

	// const handleClick = (id, data) => {
	// 	store.dispatch(getDrafts(data));
	// 	preview(id)
	// }

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setSize(parseInt(event.target.value, 10));
	};

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

	return (
		<TableContainer component={Paper} style={{
			maxHeight: 500
		}}>
			<Table sx={{ minWidth: 650 }} aria-label="simple table">
				<TableHead>
					<TableRow>
                        {tableHead.map((item, index) => {
                            return(
                                <TableCell align={index === 0 ? "left" :  "center"}><p className="header-content" >{item}</p></TableCell>
                            )
                        })}
					</TableRow>
				</TableHead>
				<TableBody>
					{tableData &&  tableData.map((row) => (
						<TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} style={{ cursor: "pointer" }}>
							<TableCell component="th" scope="row"><p className="table-content">{row.issuer}</p></TableCell>
							<TableCell component="th" scope="row" align="center"><p className="table-content">ONE - {row?.projectType.slice(6, 11)}</p></TableCell>
							<TableCell component="th" scope="row" align="center"><p className="table-content">{row.ipboCode}</p></TableCell>
							<TableCell component="th" scope="row" align="center"><p className="table-content">{numberWithCommas(row.totalNumberOfUnits)}</p></TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
			<TablePagination
				rowsPerPageOptions={[5, 10, 25]}
				component="div"
				count={elements}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</TableContainer>
	);
}
