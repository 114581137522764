export const mock = [
    {
        username: 'JP Morgan',
        email: 'jpmorgan@mail.com',
        IPbS: 1000
    },
    {
        username: 'Goldman Sachs',
        email: 'contact@goldmansachs.com',
        IPbS: 3500
    },
    {
        username: 'UBS',
        email: 'contanct@ubs.com',
        IPbS: 1500
    },
    {
        username: 'Julius Bar',
        email: 'contanct@juliusbar.com',
        IPbS: 2700
    },
    {
        username: 'John Doe',
        email: 'johnDoe@mail.com',
        IPbS: 300
    },
    {
        username: 'Universal',
        email: 'contact@universal.com',
        IPbS: 3800
    },
    {
        username: 'BMG',
        email: 'contact@bmg.com',
        IPbS: 2200
    },
]