import React from 'react';
import CustomSidebarBtn from 'components/CustomSidebarBtn/CustomSidebarBtn';
import Logo from '../../assets/png/logo.png';
import './Sidebar.scss';
import store from 'store'

const Sidebar = (props) => {


	const handleChange = name => {
		if (name === 'login') {
			store.set('loggedIn', false)
			window.location.href = `/${name}`;
		} else {
			window.location.href = `/${name}`;
		}
	}

	return (
		<div className="sidebar-compoenent">
			<div className="img-container">
				<img src={Logo} className="logo-img" alt="" />
			</div>
			<div className="links-container">
				<div className="menu-link">
					<CustomSidebarBtn name={''} text={'Drafts'} handleChange={handleChange} />
					<CustomSidebarBtn name={'approved'} text={'approved'} handleChange={handleChange} />
				</div>
				<div className='create-new'>
					<CustomSidebarBtn name={'new-ipro'} text={'create-ipro'} handleChange={handleChange} />
					<CustomSidebarBtn name={'register-ipo'} text={'registered-Ipo'} handleChange={handleChange} />
				</div>
				<div className="sign-out">
					<CustomSidebarBtn name={'login'} text={'Sign Out'} handleChange={handleChange} />
				</div>
			</div>
		</div>
	);
};

export default Sidebar;
