// FOR EACH FLOW YOU NEED TO CREATE A NEW REDUCER FILE
import * as actionTypes from '../actions/actionTypes';

const initState = {
    data: null
};

const activeReducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.SAVE_ACIVE:
            return {
                ...state,
                data: action.payload
            };
        case actionTypes.SUCCESSFUL_IPO:
            return {
                ...state,
                data: action.payload
            }

        default:
            return state;
    }
};

export default activeReducer;

