import Grid from '@mui/material/Grid';
import DistributionTable from 'components/DistributionTable/DistributionTable';
import React, { useEffect, useState } from 'react';
import { store } from 'store/Store';
import { REST } from 'utils/axiosHelper';
import './PreviewActive.scss';
import CustomButton from 'components/CustomButton/CustomButton';
import Uploader from 'components/Uploader/Uploader';

const PreviewApproved = () => {
    const [data, setData] = useState(null);
    const [distribution, setDistribution] = useState(null);
    const [isVisible, setIsVisible] = useState(false);
    const [uploaded, setUploaded] = useState(false);
    // const [documents, setDocuments] = useState(null);

    // const browserHistory = createBrowserHistory({ forceRefresh: true });


    useEffect(() => {
        setData(store.getState().draftReducer.data)
    }, [])

    const getData = async () => {
        if (data) {
            await REST.get(`/api/frr/project/getProjectDistribution?projectId=${data?.id}`)
                .then((answer) => {
                    setDistribution(answer.data);
                })
                .catch((err) => {
                    console.log(`Err: `, err);
                });
        }
    };

    // const getDocuemnts = async () => {
    //     await REST.get(`/api/frr/project/getAllProjectDocuments?projectId=${data?.id}`)
    //         .then((answer) => {
    //             setDocuments(answer.data);
    //         })
    //         .catch((err) => {
    //             console.log(`Err: `, err);
    //         });
    // };

    useEffect(() => {
        getData();
        // getDocuemnts();
        // eslint-disable-next-line
    }, [data])


    const handleSuccess = () => {
        setIsVisible(!isVisible)
    }



    return (
        <>
            {data &&
                <Grid container className="preview-active-container">
                    <Grid item xs={12} className="title-container">
                        <img src={data?.artist?.imageUrl} alt="" className="artist-img" />
                        <div>
                            <h1 className="title"><b>{data?.artist?.name}:</b> {data?.projectName}</h1>
                            <p className='title'>{data?.artist?.about}</p>
                            <p className="title"><b>Followers:</b> {data?.artist?.followerCount}</p>
                        </div>
                    </Grid>

                    <Grid item xs={12} className="register-container">
                        <CustomButton
                            name={'registerIpo'}
                            type="rounded"
                            text="Successful ipo"
                            isEnabled={true}
                            handleChange={handleSuccess}
                        />
                    </Grid>

                    {isVisible &&
                        <Grid item xs={12} md={12}>
                            <div style={{alignItems:"center"}}>
                                <Uploader setUploaded={setUploaded} projectId={data?.id} setIsVisible={setIsVisible} />
                            </div>
                        </Grid>
                    }

                    {/* <Grid item container xs={12} className="data-body">
                        <Grid item xs={12} md={6} className="content-container">
                            <p className='colored'><b>Project :</b> {data?.title}</p>
                            <p><b>Project id :</b> {data.projectName}</p>
                            <p className='colored'><b>Project type:</b> ONE</p>
                            <p><b>Project category:</b> {data.category}</p>
                            <p className='colored'><b>Genre:</b> {data.genre}</p>
                            <p><b>Tenor:</b> {data.tenor} {data.tenorSufix}</p>
                            <p className='colored'><b>Installments:</b> {data.installments}</p>
                        </Grid>
                        <Grid item xs={12} md={6} className="content-container">
                            <p><b>Estimated value:</b> {data.estimatedValue}$</p>
                            <p className='colored'><b>IRO value offer:</b> {data.valueOffer}$</p>
                            <p><b>Percentage:</b> {data.percentage}%</p>
                            <p className='colored'><b>IRO Value Offer:</b> {data.pricePerUnit}$</p>
                            <p><b>Number of units for sale:</b> {data.numberOfUnitsSold} units</p>
                            <p className='colored'><b>Estimated YIELD:</b> {data.estimtedYield}%</p>
                            <p><b>Estimated YIELD description:</b> {data.estimatedYieldDescription}</p>
                            <p className='colored'><b>Price per unit:</b> {data.pricePerUnit}$</p>
                        </Grid>
                    </Grid> */}
                    {/* <Grid item xs={12} md={12} className="documents-container">
                        <div className="files-container">
                            <h1 className="title">Documents: </h1>
                            {documents?.map((item) => {
                                return (
                                    <Tooltip title={item.filename}>
                                        <div className="item">
                                            <a href={`${beURL}/api/frr/project/getDocumentByFilename?filename=${item.filename}`} target={'_blank'} rel="noreferrer">
                                                <InsertDriveFileOutlinedIcon style={{ width: 40, height: 40 }} />
                                            </a>
                                        </div>
                                    </Tooltip>
                                )
                            })}
                        </div>
                    </Grid> */}
                    <Grid item xs={12} md={12} className="distribution-table">
                        <DistributionTable data={distribution} issuerData={data} uploaded={uploaded}/>
                    </Grid>
                </Grid>
            }
        </>
    )
}

export default PreviewApproved;