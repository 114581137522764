import React, { useEffect, useState, useRef, Fragment } from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import { REST, beURL } from 'utils/axiosHelper';
import Dropzone from "react-dropzone";
// import UploadedFiles from "./UploadedFiles";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 20,
        // backgroundColor: "#fff",
        border: `3px solid transparent`,
    },

    dropzone: {
        border: "2px dashed whitesmoke",
        textAlign: "center",
        fontFamily: "Arial",
        // backgroundColor: "#e3e3e3",
        borderRadius: "3px",
        padding: "1rem",
        margin: "1rem 0",
    },

    dropParagraph: {
        padding: "40px",
        color: "whitesmoke"
    },

    fontFamily: {
        fontFamily: "Arial",
    },
}));

const Uploader = (props) => {
    const {setUploaded, projectId, setIsVisible} = props;

    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(false);

    const [trigger, setTrigger] = useState(false);


    const didMountRef = useRef(false);

    useEffect(() => {
        if (didMountRef.current) {
        } else didMountRef.current = true;
        // eslint-disable-next-line
    });

    const onUpload = async (files) => {
        setIsLoading(true);
        const formData = new FormData();

        // eslint-disable-next-line
        files.map((item) => {
            formData.append("files", item);
        });

        // eslint-disable-next-line
        // const result = await REST.post(
        //     `${beURL}/api/frr/uploadSignedContracts`,
        //     formData
        // ).then((answer) => {
        //     setIsLoading(false);
        //     setTrigger(!trigger);
        // });


        if(window.location.pathname === `/approved/${projectId}`) {
            setUploaded(true)
            setIsVisible(false)
        }

        const test = {
            project8: true
        }

    };

    return (
        <div className={classes.root}>
            {isLoading && <div>Loading ...</div>}

            <Grid container spacing={4}>
                <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Fragment>
                        <Dropzone
                            onDrop={onUpload}
                            // accept={'.jpeg,.jpg,.png,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf'}
                            accept={".png,.jpeg,.jpg,.JPEG,.JPG"}
                            disabled={isLoading}
                        >

                            {({ getRootProps, getInputProps }) => (
                                <section className={classes.dropzone}>
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <p className={classes.dropParagraph}>
                                            Drag Files or Click to Browse
                                        </p>
                                    </div>
                                </section>
                            )}
                        </Dropzone>

                        {/* <UploadedFiles
                            trigger={trigger}
                        /> */}
                    </Fragment>
                </Grid>
            </Grid>
        </div>
    );
};

export default Uploader;
