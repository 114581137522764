// FOR EACH FLOW YOU NEED TO CREATE A NEW REDUCER FILE
import * as actionTypes from '../actions/actionTypes';

const initState = {
	data: null
};

const draftReducer = (state = initState, action) => {
	switch (action.type) {
		case actionTypes.SAVE_DRAFT:
			return {
				...state,
				data: action.payload
			};

		default:
			return state;
	}
};

export default draftReducer;

  