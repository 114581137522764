import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { getDrafts } from 'actions/myActions';
import moment from 'moment';
import React from 'react';
import { store } from 'store/Store';
import './NewTable.scss';
import TablePagination from '@mui/material/TablePagination';

export default function NewTable(props) {
	const { tableData, preview, elements, setPage, page, setSize, isDraft } = props;

	const [rowsPerPage, setRowsPerPage] = React.useState(5);

	const handleClick = (id, data) => {
		store.dispatch(getDrafts(data));
		preview(id)
	}

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setSize(parseInt(event.target.value, 10));
	};

	return (
		<TableContainer component={Paper} style={{
			maxHeight: 500
		}}>
			<Table sx={{ minWidth: 650 }} aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell><p className="header-content" >Issuer</p></TableCell>
						<TableCell align="left"><p className="header-content" >{isDraft ? "Draft ID no" : "Approved IPbS Code"}</p></TableCell>
						<TableCell align="left"><p className="header-content" >Project type</p></TableCell>
						<TableCell align="left"><p className="header-content" >Underwriter</p></TableCell>
						<TableCell align="right"><p className="header-content" >Date</p></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{tableData?.map((row) => (
						<TableRow onClick={() => handleClick(row.id, row)} key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} style={{ cursor: "pointer" }}>
							<TableCell component="th" scope="row"><p className="table-content">{row && row.artist && row.artist.name}</p></TableCell>
							<TableCell component="th" scope="row"><p className="table-content">{row.projectName}</p></TableCell>
							<TableCell component="th" scope="row"><p className="table-content">ONE - {row?.info}</p></TableCell>
							<TableCell component="th" scope="row"><p className="table-content">{row.underwriter}</p></TableCell>
							<TableCell align="right"><p className="table-content">{moment(row.creationDate).format("DD-MM-YYYY")}</p></TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
			<TablePagination
				rowsPerPageOptions={[5, 10, 25]}
				component="div"
				count={elements}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</TableContainer>
	);
}
